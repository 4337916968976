import React from "react";
import loadable from "@loadable/component";
import { Switch, Route } from "react-router";

import { Guard } from "@components/Guard";
import Header from "@components/Header";
import { retry } from "@utils";

const SchoolDashboard = loadable(() =>
  retry(() => import("./SchoolDashboard")),
);
const CorporationDashboard = loadable(() =>
  retry(() => import("./CorporationDashboard")),
);

const Dashboard: React.FC = () => {
  return (
    <div>
      <Header />
      <Switch>
        <Route
          path="/dashboard/corporations/:corporationId"
          component={CorporationDashboard}
        />
        <Route
          path="/dashboard/facilities/:facilityId"
          component={SchoolDashboard}
        />
      </Switch>
    </div>
  );
};

const Guarded: React.FC = () => (
  <Guard>
    <Dashboard />
  </Guard>
);

export default Guarded;
